import React from "react";
import { Trans } from "next-i18next";
import { Box } from "@chakra-ui/react";

const TransComponent = ({ i18nKey, value, ...rest }) => {
  return (
    <Box>
      <Trans
        i18nKey={i18nKey}
        values={{ limit: value }}
        components={{ strong: <strong></strong> }}
        {...rest}
      />
    </Box>
  );
};

export default TransComponent;
