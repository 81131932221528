import { PressOffice } from "./office";
import { PaymentStatus } from "./payment";

export type Subscription = {
  id: number;
  start: Date;
  end: Date;
  isFree: boolean;
  pressOfficeId: number;
  organizationId: number;
  priceForOrganization: number;
  adminAccountsLimit: number;
  modAccountsLimit: number;
  activeEventsLimit: number;
  mailLimit: number;
  smsLimit: number;
  cloudSpaceLimit: number;
  badgeAccess: boolean;
  pressWebsiteAccess: boolean;
  subscriptionPackageId: number;
  transactionId: number;
  subscriptionPackage: SubscriptionPackage;
  transaction: Transaction;
  usage: {
    admins: number;
    mods: number;
    events: number;
    emails: number;
    sms: number;
    cloudSpace: number;
  };
  organizationLimitsAvailableToGrant: {
    activeEventsLimit: number;
    adminAccountsLimit: number;
    cloudSpaceLimit: number;
    mailLimit: number;
    modAccountsLimit: number;
    smsLimit: number;
  };
  billingDate: {
    start: Date;
    end: Date;
  };
};

export type SubscriptionPackage = {
  id: number;
  name: SubscriptionPackageName;
  description: string;
  price: number;
  priceWithoutVat: number;
  adminAccountsLimit: number;
  modAccountsLimit: number;
  activeEventsLimit: number;
  mailLimit: number;
  smsLimit: number;
  cloudSpaceLimit: number;
  badgeAccess: boolean;
  pressWebsiteAccess: boolean;
  discounts: SubscriptionTimePeriodDiscount[];
};

export type SubscriptionTimePeriodDiscount = {
  percent: number;
  monthsPeriod: number;
  price: number;
  priceWithoutVat: number;
};

export type Transaction = {
  id: number;
  paymentId: string;
  externalId: string;
  currency: string;
  description: string;
  status: PaymentStatus;
  finishedDate: Date;
  pressOfficeId: number;
  pressOffice: PressOffice;
  organizationId: number;
  type: "subscription" | "maintenance";
  subscriptionPackageId: number;
  subscriptionPackage: SubscriptionPackage;
  maintenancePackageId: number;
  maintenancePackage: MaintenancePackage;
  subscriptionPeriodInMonths: number;
  amount: number;
  amountNet: number;
  timePeriodDiscountId: number;
  timePeriodDiscount: SubscriptionTimePeriodDiscount;
};

export type MaintenancePackage = {
  id: number;
  name: string;
  description: string;
  periodInMonths: number;
  price: number;
  priceWithoutVat: number;
};

export type Maintenance = {
  id: number;
  start: Date;
  end: Date;
  pressOfficeId: number;
  pressOffice: PressOffice;
  organizationId: number;
  transactionId: number;
  transaction: Transaction;
  maintenancePackageId: number;
  maintenancePackage: MaintenancePackage;
};

export enum SubscriptionPackageName {
  BASIC = "BASIC",
  STANDARD = "STANDARD",
  PROFESSIONAL = "PROFESSIONAL",
  ENTERPRISE = "ENTERPRISE",
}
