import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  faPaperPlane,
  faPlane,
  faRocket,
  faShuttleSpace,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { filesize } from "filesize";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import {
  CheckCircle,
  Grid,
  Mail,
  MinusCircle,
  Send,
  UploadCloud,
  Users,
} from "react-feather";

import BoxCard from "@/components/common/BoxCard";
import SubscriptionIcon from "@/components/common/SubscriptionIcon";
import {
  default as Trans,
  default as TransComponent,
} from "@/components/common/Trans";
import { SubscriptionPackageName } from "@/types/subscription";

export const subscriptionIcon = {
  [SubscriptionPackageName.BASIC]: faPaperPlane,
  [SubscriptionPackageName.STANDARD]: faPlane,
  [SubscriptionPackageName.PROFESSIONAL]: faRocket,
  ["ENTERPRISE"]: faShuttleSpace,
};

type Props = {
  subscriptionData: any;
  offer?: boolean;
  admin?: boolean;
  onClick?: () => void;
};

const SubscriptionCard = ({
  subscriptionData,
  offer = true,
  admin = false,
  onClick,
}: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const Icon = chakra(FontAwesomeIcon);
  const [isShown, setIsShown] = useState(true);

  return (
    <>
      <BoxCard
        textAlign="center"
        transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
        transitionDuration=".3s"
        onMouseEnter={() => setIsShown(false)}
        onMouseLeave={() => setIsShown(true)}
        _hover={{
          transform: "scale(1.05)",
          boxShadow:
            "0 0 transparent, 0 0 transparent, 0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04)",
        }}
        onClick={onClick}
        cursor="pointer"
        ml={
          (offer || admin) && subscriptionData?.name !== "ENTERPRISE"
            ? undefined
            : 5
        }
        bg={"white"}
        h="100%"
      >
        {subscriptionIcon[subscriptionData?.name] && (
          <SubscriptionIcon
            packageIcon={subscriptionIcon[subscriptionData?.name]}
            packageName={subscriptionData?.name?.toUpperCase()}
            card={isShown ? true : false}
          />
        )}
        <Heading as="h5" size="lg" textTransform={"uppercase"}>
          {subscriptionData?.name?.toUpperCase()}
        </Heading>
        <Box>
          {subscriptionData?.name !== "ENTERPRISE" ? (
            <>
              {(offer || admin) && (
                <Text
                  fontSize="3rem"
                  fontWeight="600"
                  color="text.900"
                  lineHeight="1"
                >
                  {subscriptionData?.price} {/* TODO inne waluty */}
                  <chakra.span fontSize="1.5rem">PLN</chakra.span>
                </Text>
              )}
              {!offer && subscriptionData?.end && (
                <>
                  <Text fontWeight={700}>
                    {t("payments.subscription.endDate")}
                  </Text>
                  <Text fontWeight="600" fontSize="xl" mt={1}>
                    {format(new Date(subscriptionData?.end), "dd/MM/yyy")}
                  </Text>
                </>
              )}
              <Box mt={5} color="text.700">
                <Flex align="center" textAlign="left" lineHeight={1.2}>
                  <Icon as={Users} w="15px" h="15px" mr={2} />
                  <TransComponent
                    i18nKey="payments.subscription.adminLimit"
                    value={subscriptionData?.adminAccountsLimit}
                    count={subscriptionData?.adminAccountsLimit}
                  />
                </Flex>
                <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                  <Icon as={Users} w="15px" h="15px" mr={2} />
                  <TransComponent
                    i18nKey="payments.subscription.moderatorLimit"
                    value={subscriptionData?.modAccountsLimit}
                    count={subscriptionData?.modAccountsLimit}
                  />
                </Flex>
                <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                  <Icon as={Send} w="15px" h="15px" mr={2} />
                  <Trans
                    i18nKey="payments.subscription.smsLimit"
                    value={subscriptionData?.smsLimit}
                  />
                </Flex>
                <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                  <Icon as={Mail} w="15px" h="15px" mr={2} />
                  <Trans
                    i18nKey="payments.subscription.mailLimit"
                    value={subscriptionData?.mailLimit}
                  />
                </Flex>
                <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                  <Icon as={UploadCloud} w="15px" h="15px" mr={2} />
                  <Trans
                    i18nKey="payments.subscription.cloudLimit"
                    value={filesize(subscriptionData?.cloudSpaceLimit, {
                      base: 2,
                      standard: "jedec",
                    })}
                  />
                </Flex>
                <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                  <Icon as={Grid} w="15px" h="15px" mr={2} />
                  <Trans
                    i18nKey="payments.subscription.eventsLimit"
                    value={subscriptionData?.activeEventsLimit}
                  />
                </Flex>
                <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                  {subscriptionData?.pressWebsiteAccess ? (
                    <Icon as={CheckCircle} w="15px" h="15px" mr={2} />
                  ) : (
                    <Icon as={MinusCircle} w="15px" h="15px" mr={2} />
                  )}
                  <Text>{t("payments.subscription.pageService")}</Text>
                </Flex>
              </Box>
            </>
          ) : (
            <>
              <Text
                fontSize="1rem"
                fontWeight="600"
                color="text.900"
                lineHeight="1.2"
                mb={8}
              >
                {t("payments.subscription.indyvidualConfiguration")}
              </Text>
              <Flex align="center" textAlign="left" lineHeight={1.2}>
                <Icon as={Users} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
              <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                <Icon as={Users} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
              <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                <Icon as={Send} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
              <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                <Icon as={Mail} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
              <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                <Icon as={UploadCloud} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
              <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                <Icon as={Grid} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
              <Flex align="center" textAlign="left" lineHeight={1.2} mt={3}>
                <Icon as={CheckCircle} w="15px" h="15px" mr={2} />
                <Text>{t("payments.subscription.individualConfig")}</Text>
              </Flex>
            </>
          )}
        </Box>
        {(offer || admin) && subscriptionData?.name !== "ENTERPRISE" && (
          <Tooltip
            label={
              !admin ? t("payments.subscription.buy") : t("payments.admin.edit")
            }
            placement="bottom"
          >
            <Button
              mt={5}
              disabled={subscriptionData?.name === "ENTERPRISE"}
              onClick={onClick}
              justifySelf="end"
            >
              {!admin
                ? subscriptionData?.name !== "ENTERPRISE"
                  ? t("choose")
                  : t("website.contact")
                : t("edit")}
            </Button>
          </Tooltip>
        )}
        {!admin && offer && subscriptionData?.name === "ENTERPRISE" && (
          <Tooltip
            label={t("payments.subscription.contactWithUs")}
            placement="bottom"
          >
            <Button mt={5} onClick={onClick} justifySelf="end">
              {/* TODO navigate to contact when enterprise choosen */}
              {t("website.contact")}{" "}
            </Button>
          </Tooltip>
        )}
      </BoxCard>
    </>
  );
};

export default SubscriptionCard;
