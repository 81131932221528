import React from "react";
import { Box, chakra, keyframes } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import FlyAnimation from "@/components/common/Animations/FlyAnimation.json";

const SubscriptionIcon = ({
  packageName,
  packageIcon,
  card = false,
  ...props
}): JSX.Element => {
  const Icon = chakra(FontAwesomeIcon);

  const planeAnimation = keyframes`
    0% {
      transform: translateY(-5px) rotate(${
        packageName === "STANDARD" || packageName === "ENTERPRISE"
          ? "-45deg"
          : "0"
      })
    }
    50% {
      transform: translateY(5px) rotate(${
        packageName === "STANDARD" || packageName === "ENTERPRISE"
          ? "-45deg"
          : "0"
      })
    }
    100% {
      transform: translateY(-5px) rotate(${
        packageName === "STANDARD" || packageName === "ENTERPRISE"
          ? "-45deg"
          : "0"
      })
    }
  `;

  const animationStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    transform: "rotate(-15deg)",
    marginTop: -15,
    opacity: card ? 0 : 1,
    transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
    transitionDuration: ".3s",
  };

  return (
    <Box w="144px" h="144px" position="relative" mx="auto">
      <Lottie animationData={FlyAnimation} style={animationStyle} />
      <Icon
        icon={packageIcon}
        w={20}
        h={20}
        p={8}
        borderRadius={100}
        color="main.800"
        animation={card ? null : `${planeAnimation} infinite 2s`}
        transform={`rotate(${
          packageName === "STANDARD" || packageName === "ENTERPRISE"
            ? "-45deg"
            : "0"
        });`}
        {...props}
      />
    </Box>
  );
};

export default SubscriptionIcon;
